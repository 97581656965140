import React from "react"
import Container from "../components/container"
import User from "../components/user"

export default () => (
  <Container>
    <h1>About CSS Module</h1>
    <p>CSS Modules are cool</p>
    <User
      username="Dan"
      avatar="https://s3.amazonaws.com/uifaces/faces/twitter/adellecharles/128.jpg"
      excerpt="I'm Dan Sack. Lorem ipsum dolor sit amet, consectetur adipisicing elit.">
    </User>
    <User
      username="Cleo"
      avatar="https://s3.amazonaws.com/uifaces/faces/twitter/vladarbatov/128.jpg"
      excerpt="I'm Cleo. Lorem ipsum dolor sit amet, consectetur adipisicing elit.">
    </User>
  </Container>
)